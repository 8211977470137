const sortByNumber = (a, b) => {
  // Extract the first number from each filename
  const getNumber = (filename) => {
    const match = filename.match(/\d+/);
    return match ? parseInt(match[0]) : 0;
  };

  const numA = getNumber(a);
  const numB = getNumber(b);

  if (numA === numB) {
    // If numbers are the same, sort alphabetically
    return a.localeCompare(b);
  }

  // Sort in descending order (higher numbers first)
  return numB - numA;
};

// Static gallery data
const galleryData = {
  '360photobooth': [
    '/gallery/360photobooth/01.PNG',
    '/gallery/360photobooth/02.PNG',
    '/gallery/360photobooth/03.PNG',
    '/gallery/360photobooth/04.PNG',
    '/gallery/360photobooth/05.MOV',
    '/gallery/360photobooth/IMG_2208.MOV',
    '/gallery/360photobooth/Output 2.MP4',
    '/gallery/360photobooth/video_1734838625.MP4'
  ],
  'nightclub': [
    '/gallery/nightclub/IMG_2066.mov',
    '/gallery/nightclub/empty.mp4'
  ]
};

export const loadGalleryImages = async (category) => {
  try {
    const mediaFiles = galleryData[category] || [];
    return mediaFiles.sort(sortByNumber);
  } catch (error) {
    console.error('Error loading gallery:', error);
    return [];
  }
};

export const isVideo = (url) => {
  return url.toLowerCase().match(/\.(mp4|webm|mov)$/i) !== null;
};
