import React from 'react';
import { Container, Typography, Box, Grid, Button, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check as CheckIcon } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import servicesData from '../data/services.json';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '60vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  backgroundImage: 'url(/images/dance-background.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

const PackageCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
}));

const CardContentStyled = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  '& .MuiBox-root:last-child': {
    marginTop: 'auto',
    paddingTop: '24px',
  },
});

const LEDDanceFloor = () => {
  return (
    <Box>
      <Helmet>
        <title>LED Dance Floor Rentals Los Angeles - Luxe360 LA</title>
        <meta
          name="description"
          content="Transform your event with our premium LED Dance Floor rentals in Los Angeles. Available in sizes up to 20' x 20' with custom lighting patterns and professional setup."
        />
      </Helmet>

      <HeroSection>
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" component="h1" gutterBottom>
              LED Dance Floor
            </Typography>
            <Typography variant="h5" gutterBottom>
              Illuminate Your Event with Our Premium LED Dance Floor
            </Typography>
          </motion.div>
        </Container>
      </HeroSection>

      <Container maxWidth="lg">
        <Box sx={{ py: 8 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom>
                Create an Unforgettable Dance Experience
              </Typography>
              <Typography paragraph>
                Our state-of-the-art LED dance floor brings the wow factor to any event.
                Available in sizes up to 20' x 20', featuring stunning light patterns,
                custom monograms, and professional installation.
              </Typography>
              <Typography paragraph>
                Perfect for weddings, corporate events, birthday parties, or any special occasion
                where you want to create an immersive and dynamic atmosphere that will leave
                your guests amazed.
              </Typography>
              <Button
                component={Link}
                to="/contact"
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 2 }}
              >
                Book Now
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="video"
                autoPlay
                muted
                loop
                playsInline
                preload="none"
                poster="/images/dancefloor-preload.png"
                sx={{
                  width: '75%',
                  height: 'auto',
                  borderRadius: 2,
                  margin: '0 auto',
                  display: 'block'
                }}
              >
                <source src="/images/ledfloor-opt.mp4" type="video/mp4" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ py: 8 }}>
          <Typography variant="h3" align="center" gutterBottom>
            Packages
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <PackageCard>
                <CardContentStyled>
                  <Typography variant="h4" component="h2" gutterBottom>
                    {servicesData.ledDanceFloorPackage.title}
                  </Typography>
                  <Typography variant="h3" color="primary" gutterBottom>
                    ${servicesData.ledDanceFloorPackage.price}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {servicesData.ledDanceFloorPackage.duration}
                  </Typography>
                  {servicesData.ledDanceFloorPackage.additionalHourRate && (
                    <Typography variant="subtitle1" color="primary" gutterBottom>
                      Additional hours: ${servicesData.ledDanceFloorPackage.additionalHourRate}/hour
                    </Typography>
                  )}
                  <List>
                    {servicesData.ledDanceFloorPackage.features.map((feature, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemIcon>
                          <CheckIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      component={Link}
                      to="/contact"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Book Now
                    </Button>
                  </Box>
                </CardContentStyled>
              </PackageCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <PackageCard>
                <CardContentStyled>
                  <Typography variant="h4" component="h2" gutterBottom>
                    {servicesData.ledDanceFloorSmallPackage.title}
                  </Typography>
                  <Typography variant="h3" color="primary" gutterBottom>
                    ${servicesData.ledDanceFloorSmallPackage.price}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {servicesData.ledDanceFloorSmallPackage.duration}
                  </Typography>
                  <Typography variant="subtitle1" color="primary" gutterBottom>
                    Additional hours: ${servicesData.ledDanceFloorSmallPackage.additionalHourRate}/hour
                  </Typography>
                  <List>
                    {servicesData.ledDanceFloorSmallPackage.features.map((feature, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemIcon>
                          <CheckIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      component={Link}
                      to="/contact"
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      Book Now
                    </Button>
                  </Box>
                </CardContentStyled>
              </PackageCard>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ py: 8 }}>
          <Typography variant="h3" align="center" gutterBottom>
            Available Add-ons
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {servicesData.addons.ledDanceFloorAddons.map((addon) => (
              <Grid item xs={12} md={4} key={addon.id}>
                <PackageCard>
                  <CardContentStyled>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {addon.title}
                    </Typography>
                    <Typography variant="h4" color="primary" gutterBottom>
                      ${addon.price}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ flexGrow: 1 }}>
                      {addon.description}
                    </Typography>
                  </CardContentStyled>
                </PackageCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LEDDanceFloor;
