import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: 'white',
  '& .background-container': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url(/images/la-background.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  '& .logo-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    zIndex: 1,
  },
  '& .luxe-logo': {
    maxWidth: '90%',
    width: '600px',
    height: 'auto',
    display: 'block',
    margin: '0 auto',
  },
}));

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
  display: 'block',
  height: '100%',
  '&:hover': {
    color: 'inherit',
    textDecoration: 'none',
  },
});

const ServiceTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',  // Slightly smaller than h4
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  whiteSpace: 'nowrap',  // Prevent wrapping
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const ServiceCard = styled(motion.div)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  height: '100%',
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
}));

const Home = () => {
  return (
    <Box>
      <HeroSection>
        <div className="background-container" />
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box className="logo-container">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <img src="/images/luxe360_logo_white.png" alt="Luxe 360 LA Logo" className="luxe-logo" />
            </motion.div>
          </Box>
        </Container>
      </HeroSection>

      <Box sx={{ py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" gutterBottom>
            Our Services
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <StyledLink to="/photo-booth">
                <ServiceCard
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                <ServiceTitle>
                  360° Photo Booth
                </ServiceTitle>
                <Typography>
                  Create stunning 360-degree videos that capture every angle of your special moments.
                  Perfect for weddings, parties, and corporate events.
                </Typography>
                </ServiceCard>
              </StyledLink>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledLink to="/nightclub">
                <ServiceCard
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                <ServiceTitle>
                  Inflatable Nightclub
                </ServiceTitle>
                <Typography>
                  Transform any space into an exclusive VIP nightclub experience.
                  Complete with state-of-the-art sound and lighting systems.
                </Typography>
                </ServiceCard>
              </StyledLink>
            </Grid>
            <Grid item xs={12} md={4}>
              <StyledLink to="/led-dance-floor">
                <ServiceCard
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.3 }}
                >
                <ServiceTitle>
                  LED Dance Floor
                </ServiceTitle>
                <Typography>
                  Elevate your event with our stunning LED dance floor.
                  Available in multiple sizes with customizable lighting patterns.
                </Typography>
                </ServiceCard>
              </StyledLink>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h2" align="center" gutterBottom>
            Why Choose Us?
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h5" gutterBottom>
                  Premium Equipment
                </Typography>
                <Typography>
                  Top-of-the-line photo booth technology and nightclub-quality sound systems.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h5" gutterBottom>
                  Professional Service
                </Typography>
                <Typography>
                  Experienced staff ensuring smooth operation and guest satisfaction.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box textAlign="center">
                <Typography variant="h5" gutterBottom>
                  Customizable Packages
                </Typography>
                <Typography>
                  Flexible options to match your event needs and budget.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
