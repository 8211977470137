import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const Privacy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary" paragraph>
          Last updated: March 31, 2025
        </Typography>

        <ContentPaper elevation={3}>
          <Section>
            <Typography variant="h5" gutterBottom>
              1. Information We Collect
            </Typography>
            <Typography paragraph>
              We collect information that you provide directly to us, including:
            </Typography>
            <Typography component="ul" sx={{ pl: 3 }}>
              <li>Name and contact information</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Event details and preferences</li>
              <li>Messages and communications with us</li>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" gutterBottom>
              2. How We Use Your Information
            </Typography>
            <Typography paragraph>
              We use the information we collect to:
            </Typography>
            <Typography component="ul" sx={{ pl: 3 }}>
              <li>Process and fulfill your bookings</li>
              <li>Communicate with you about our services</li>
              <li>Send you marketing communications (with your consent)</li>
              <li>Improve our services and customer experience</li>
              <li>Comply with legal obligations</li>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" gutterBottom>
              3. Information Sharing
            </Typography>
            <Typography paragraph>
              We do not sell or rent your personal information to third parties. We may share your information with:
            </Typography>
            <Typography component="ul" sx={{ pl: 3 }}>
              <li>Service providers who assist in our operations</li>
              <li>Legal authorities when required by law</li>
              <li>Professional advisers as necessary</li>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" gutterBottom>
              4. Your Rights
            </Typography>
            <Typography paragraph>
              You have the right to:
            </Typography>
            <Typography component="ul" sx={{ pl: 3 }}>
              <li>Access your personal information</li>
              <li>Correct inaccurate information</li>
              <li>Request deletion of your information</li>
              <li>Opt-out of marketing communications</li>
              <li>Withdraw consent at any time</li>
            </Typography>
          </Section>

          <Section>
            <Typography variant="h5" gutterBottom>
              5. Contact Us
            </Typography>
            <Typography paragraph>
              If you have any questions about this Privacy Policy, please contact us at:
            </Typography>
            <Typography paragraph>
              Email: hello@luxe360la.com<br />
              Phone: (818) 527-6297
            </Typography>
          </Section>
        </ContentPaper>
      </Box>
    </Container>
  );
};

export default Privacy;
