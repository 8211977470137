import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const LogoImage = styled('img')({
  height: '40px',
  width: 'auto',
});

const Logo = () => {
  return (
    <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
      <LogoImage src="/images/luxe360_logo_white.png" alt="Luxe 360 LA" />
    </Box>
  );
};

export default Logo;
