import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Paper,
} from '@mui/material';
import emailjs from '@emailjs/browser';

const SimpleBookingForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    date: null,
    time: '',
    hours: '',
    message: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await emailjs.send(
        'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
        'YOUR_TEMPLATE_ID', // Replace with your EmailJS template ID
        {
          to_name: 'Business Owner',
          from_name: formData.name,
          from_email: formData.email,
          phone: formData.phone,
          service: formData.service,
          date: formData.date ? formData.date.toLocaleDateString() : '',
          time: formData.time,
          hours: formData.hours,
          message: formData.message,
        },
        'YOUR_PUBLIC_KEY' // Replace with your EmailJS public key
      );

      setSnackbar({
        open: true,
        message: 'Booking request sent successfully! We will contact you shortly.',
        severity: 'success',
      });

      // Clear form
      setFormData({
        name: '',
        email: '',
        phone: '',
        service: '',
        date: null,
        time: '',
        hours: '',
        message: '',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error sending booking request. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Book Your Event
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Your Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              select
              label="Service"
              name="service"
              value={formData.service}
              onChange={handleChange}
            >
              <MenuItem value="360-photo-booth">360° Photo Booth</MenuItem>
              <MenuItem value="inflatable-nightclub">Inflatable Nightclub</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Event Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Preferred Time"
              name="time"
              value={formData.time}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              label="Number of Hours"
              name="hours"
              type="number"
              inputProps={{
                min: 2
              }}
              value={formData.hours}
              onChange={handleChange}
              helperText="2 hour minimum required"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary" paragraph>
              Please review our <Link to="/contract" style={{ color: '#1976d2' }}>rental agreement</Link> before proceeding.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Additional Message"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleChange}
              placeholder="Please list any add-ons you'd like to include (e.g., LED Lighting, Premium Props, etc.) and any other special requests."
            />
          </Grid>

          <Grid item xs={12}>
            <Typography paragraph color="text.secondary">
              By submitting this form, you agree to our{' '}
              <Link to="/contract" target="_blank" style={{ color: '#f5c518' }}>
                Terms and Conditions
              </Link>
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Submit Booking Request
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default SimpleBookingForm;
