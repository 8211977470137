import React from 'react';
import { Container, Typography, Box, Grid, Button, Card, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Check as CheckIcon } from '@mui/icons-material';
import servicesData from '../data/services.json';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '60vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  backgroundImage: 'url(/images/IMG_3160.PNG)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

const PackageCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
}));

const CardContentStyled = styled(CardContent)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

const PhotoBooth = () => {
  return (
    <Box>
      <HeroSection>
        <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" component="h1" gutterBottom>
              360° Photo Booth Experience
            </Typography>
            <Typography variant="h5" gutterBottom>
              Create Unforgettable Moments from Every Angle
            </Typography>
          </motion.div>
        </Container>
      </HeroSection>

      <Container maxWidth="lg">
        <Box sx={{ py: 8 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" gutterBottom>
                The Ultimate Photo Experience
              </Typography>
              <Typography paragraph>
                Our state-of-the-art 360° photo booth creates stunning slow-motion videos that capture
                every moment from all angles. Perfect for making your guests feel like stars!
              </Typography>
              <Typography paragraph>
                Whether it's a wedding, corporate event, birthday party, or any special occasion,
                our photo booth adds an element of excitement and entertainment that your guests
                will never forget.
              </Typography>
              <Button
                component={Link}
                to="/contact"
                variant="contained"
                color="primary"
                size="large"
                sx={{ mt: 2 }}
              >
                Book Now
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="video"
                autoPlay
                muted
                loop
                playsInline
                preload="none"
                poster="/images/photobooth-preload.png"
                sx={{
                  width: '75%',
                  height: 'auto',
                  borderRadius: 2,
                  margin: '0 auto',
                  display: 'block'
                }}
              >
                <source src="/images/photobooth-opt.mp4" type="video/mp4" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ py: 8 }}>
          <Typography variant="h3" align="center" gutterBottom>
            Base Package
          </Typography>
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <PackageCard sx={{ maxWidth: '800px', width: '100%' }}>
              <CardContentStyled>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {servicesData.photoBoothPackage.title}
                    </Typography>
                    <Typography variant="h3" color="primary" gutterBottom>
                      ${servicesData.photoBoothPackage.price}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      {servicesData.photoBoothPackage.duration}
                    </Typography>
                    {servicesData.photoBoothPackage.additionalHourRate && (
                      <Typography variant="subtitle1" color="primary" gutterBottom>
                        Additional hours: ${servicesData.photoBoothPackage.additionalHourRate}/hour
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <List sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      {servicesData.photoBoothPackage.features.map((feature, index) => (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon>
                            <CheckIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={feature} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    component={Link}
                    to="/contact"
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Book Now
                  </Button>
                </Box>
              </CardContentStyled>
            </PackageCard>
          </Box>
        </Box>

        <Box sx={{ py: 8 }}>
          <Typography variant="h3" align="center" gutterBottom>
            Available Add-ons
          </Typography>
          <Grid container spacing={4} sx={{ mt: 4 }}>
            {servicesData.addons.photoBoothAddons.map((addon) => (
              <Grid item xs={12} md={4} key={addon.id}>
                <PackageCard>
                  <CardContentStyled>
                    <Typography variant="h4" component="h2" gutterBottom>
                      {addon.title}
                    </Typography>
                    <Typography variant="h4" color="primary" gutterBottom>
                      ${addon.price}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ flexGrow: 1 }}>
                      {addon.description}
                    </Typography>
                  </CardContentStyled>
                </PackageCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PhotoBooth;
