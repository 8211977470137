import React from 'react';
import { Box, Container, Grid, Typography, IconButton, Link } from '@mui/material';
import { Instagram, Facebook, Email, Phone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledFooter = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  padding: theme.spacing(6, 0),
  marginTop: 'auto',
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  '&:hover': {
    color: '#f5c518',
  },
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              LUXE360LA
            </Typography>
            <Typography variant="body2">
              Bringing luxury entertainment to your events with our 360° Photo Booth and Inflatable Nightclub experiences in the Greater Los Angeles area.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Contact Us
            </Typography>
            <Box display="flex" alignItems="center" mb={1}>
              <Email sx={{ mr: 1 }} />
              <Link href="mailto:hello@luxe360la.com" color="inherit" underline="hover">
                hello@luxe360la.com
              </Link>
            </Box>
            <Box display="flex" alignItems="center">
              <Phone sx={{ mr: 1 }} />
              <Link href="tel:+18185276297" color="inherit" underline="hover">
                (818) 527-6297
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              <SocialButton href="https://instagram.com/luxe360la" target="_blank" aria-label="Follow us on Instagram">
                <Instagram />
              </SocialButton>
              <SocialButton href="https://facebook.com/luxe360la" target="_blank" aria-label="Follow us on Facebook">
                <Facebook />
              </SocialButton>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} LUXE360LA. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
