import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SimpleBookingForm from '../components/Booking/SimpleBookingForm';

const Book = () => {
  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Book Your Event
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Select your package and preferred date
        </Typography>

        <Box sx={{ mt: 4 }}>
          <SimpleBookingForm />
        </Box>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h6" gutterBottom>
            Booking Instructions:
          </Typography>
          <Typography paragraph>
            1. Choose your desired service (360° Photo Booth or Inflatable Nightclub)
          </Typography>
          <Typography paragraph>
            2. Select your preferred package
          </Typography>
          <Typography paragraph>
            3. Pick an available date and time
          </Typography>
          <Typography paragraph>
            4. Fill in your details and complete the booking
          </Typography>
          <Typography paragraph>
            5. Pay the deposit to secure your booking
          </Typography>
          <Typography paragraph>
            6. <Link to="/contract" style={{ color: '#1976d2', textDecoration: 'none' }}>Review and print the rental agreement</Link>
          </Typography>
          
          <Typography variant="h6" sx={{ mt: 4 }} gutterBottom>
            Important Notes:
          </Typography>
          <Typography paragraph>
            • A 25% deposit is required to secure your booking
          </Typography>
          <Typography paragraph>
            • Remaining balance is due 7 days before the event
          </Typography>
          <Typography paragraph>
            • Free cancellation up to 14 days before the event
          </Typography>
          <Typography paragraph>
            • Please contact us directly for custom packages or special requests
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Book;
