import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Link,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Email as EmailIcon, Phone as PhoneIcon, Instagram as InstagramIcon } from '@mui/icons-material';

const ContactCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ContactItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const Contact = () => {

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h2" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            Ready to make your event unforgettable? Get in touch with us!
          </Typography>
        </motion.div>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <ContactCard elevation={3}>
              <Typography variant="h4" gutterBottom>
                Contact Information
              </Typography>
              
              <ContactItem>
                <EmailIcon color="primary" />
                <Box>
                  <Typography variant="h6" gutterBottom>Email</Typography>
                  <Link href="mailto:hello@luxe360la.com" color="inherit" underline="hover">
                    hello@luxe360la.com
                  </Link>
                </Box>
              </ContactItem>

              <ContactItem>
                <PhoneIcon color="primary" />
                <Box>
                  <Typography variant="h6" gutterBottom>Phone</Typography>
                  <Link href="tel:+1234567890" color="inherit" underline="hover">
                    (818) 527-6297
                  </Link>
                </Box>
              </ContactItem>

              <ContactItem>
                <InstagramIcon color="primary" />
                <Box>
                  <Typography variant="h6" gutterBottom>Instagram</Typography>
                  <Link href="https://instagram.com/luxe360la" target="_blank" rel="noopener noreferrer" color="inherit" underline="hover">
                    @luxe360la
                  </Link>
                </Box>
              </ContactItem>
            </ContactCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <ContactCard elevation={3}>
              <Typography variant="h4" gutterBottom>
                Business Hours
              </Typography>
              <Typography paragraph>
                Monday - Friday: 9:00 AM - 6:00 PM PST
              </Typography>
              <Typography paragraph>
                Saturday: 10:00 AM - 4:00 PM PST
              </Typography>
              <Typography paragraph>
                Sunday: Closed
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                * Event services are available outside of business hours
              </Typography>
            </ContactCard>
          </Grid>
        </Grid>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h4" gutterBottom>
            Location
          </Typography>
          <Typography paragraph>
            We serve the greater Los Angeles area and surrounding regions. Contact us for availability in your area.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Contact;
