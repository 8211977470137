import React, { useState } from 'react';
import { Container, Typography, Box, Button, Paper, TextField, Grid } from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';

const Contract = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    eventDate: '',
    eventLocation: '',
    service: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handlePrint = () => {
    window.print();
  };



  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Paper sx={{ p: 4 }} className="print-content">
          <Typography variant="h4" gutterBottom align="center">
            RENTAL AGREEMENT AND LIABILITY WAIVER
          </Typography>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Client Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="print-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="print-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="print-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Event Date"
                  name="eventDate"
                  type="date"
                  value={formData.eventDate}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  className="print-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Event Location"
                  name="eventLocation"
                  value={formData.eventLocation}
                  onChange={handleChange}
                  className="print-field"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Service"
                  name="service"
                  select
                  value={formData.service}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  className="print-field"
                >
                  <option value=""></option>
                  <option value="360-photo-booth">360° Photo Booth</option>
                  <option value="inflatable-nightclub">Inflatable Nightclub</option>
                </TextField>
              </Grid>
            </Grid>
          </Box>

          <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
            1. RENTAL TERMS AND CONDITIONS
          </Typography>
          <Typography paragraph>
            This agreement is made between Luxe360LA ("Provider") and the client ("Renter") for the rental of equipment and services as specified in the booking details.
          </Typography>

          <Typography variant="h6" gutterBottom>
            2. PAYMENT AND DEPOSITS
          </Typography>
          <Typography paragraph>
            a) A 50% deposit is required to secure the booking.<br />
            b) The remaining balance is due 7 days prior to the event date.<br />
            c) Additional hours will be billed at the rates specified in the pricing section.<br />
            d) Cancellations made less than 14 days before the event will forfeit the deposit.
          </Typography>

          <Typography variant="h6" gutterBottom>
            3. LIABILITY AND DAMAGES
          </Typography>
          <Typography paragraph>
            a) The Renter assumes full responsibility for any damage to the equipment during the rental period.<br />
            b) The Renter agrees to pay for repairs or replacement of equipment damaged due to negligence or misuse.<br />
            c) For the Inflatable Nightclub, the Renter must ensure:<br />
            - A clear, flat surface of appropriate size<br />
            - No sharp objects near the inflatable<br />
            - Proper supervision during use<br />
            d) For the 360° Photo Booth, the Renter must ensure:<br />
            - A safe, covered area if outdoors<br />
            - Protection from water or liquid damage<br />
            - Proper electrical supply as specified
          </Typography>

          <Typography variant="h6" gutterBottom>
            4. SAFETY AND OPERATION
          </Typography>
          <Typography paragraph>
            a) Only authorized personnel may operate the equipment.<br />
            b) The Provider will supply trained staff for setup, operation, and breakdown.<br />
            c) The Renter must follow all safety instructions provided.<br />
            d) Maximum capacity and weight limits must be observed.
          </Typography>

          <Typography variant="h6" gutterBottom>
            5. INSURANCE AND INDEMNIFICATION
          </Typography>
          <Typography paragraph>
            a) The Provider maintains liability insurance for equipment operation.<br />
            b) The Renter agrees to indemnify the Provider against any claims arising from the use of the equipment.<br />
            c) The Renter is responsible for obtaining any additional event insurance if required.
          </Typography>

          <Typography variant="h6" gutterBottom>
            6. FORCE MAJEURE
          </Typography>
          <Typography paragraph>
            Neither party shall be liable for failure to perform due to circumstances beyond reasonable control, including but not limited to: severe weather, natural disasters, or public emergencies.
          </Typography>

          <Box sx={{ mt: 6, borderTop: '1px solid #333', pt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Client Signature
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #333',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 1,
                    cursor: 'text',
                  }}
                  className="signature-box"
                >
                  <Typography variant="body2" color="text.secondary">
                    Type your full name to sign
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="Type your full name"
                  name="signature"
                  value={formData.signature || ''}
                  onChange={handleChange}
                  className="print-field"
                />
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                  By typing your name above, you acknowledge that this constitutes your electronic signature
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Date
                </Typography>
                <TextField
                  fullWidth
                  variant="standard"
                  type="date"
                  name="signatureDate"
                  value={formData.signatureDate || ''}
                  onChange={handleChange}
                  className="print-field"
                />
              </Grid>
            </Grid>
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 4, mb: 2, fontStyle: 'italic' }}>
            This document serves as your official rental agreement. Please print or save a copy for your records.
          </Typography>

          <Box className="print-only" sx={{ mt: 6, pt: 4, display: 'none' }}>
            <Typography variant="h6" gutterBottom>
              Physical Signature (Required for Scanned Copy)
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Client Signature
                </Typography>
                <Box sx={{ 
                  border: '1px solid #333', 
                  height: '100px', 
                  mb: 1,
                  mt: 2
                }} />
                <Typography variant="caption">
                  Sign above
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Date
                </Typography>
                <Box sx={{ 
                  border: '1px solid #333', 
                  height: '100px',
                  mb: 1,
                  mt: 2
                }} />
                <Typography variant="caption">
                  Write date above
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ mt: 4, fontStyle: 'italic' }}>
              After signing, please scan and email this document to: hello@luxe360la.com
            </Typography>
          </Box>
        </Paper>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handlePrint}
          >
            Download Contract
          </Button>
        </Box>
      </Box>

      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            .print-content, .print-content * {
              visibility: visible;
            }
            .print-content {
              position: absolute;
              left: 0;
              top: 0;
            }
            button {
              display: none;
            }
            .print-field {
              border: none;
            }
            .print-field .MuiOutlinedInput-notchedOutline {
              border: none;
            }
            .print-field input {
              padding: 0;
              height: auto;
              font-size: 1rem;
            }
            .print-field label {
              position: relative;
              transform: none;
              font-size: 0.875rem;
            }
            .signature-box {
              border: none !important;
              height: 50px !important;
            }
            .print-only {
              display: block !important;
              break-inside: avoid;
              page-break-inside: avoid;
            }
          }
        `}
      </style>
    </Container>
  );
};

export default Contract;
