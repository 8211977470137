import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, Tab, Tabs } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { loadGalleryImages, isVideo } from '../utils/galleryUtils';

const GalleryImage = styled(motion.img)(({ theme }) => ({
  width: '100%',
  height: '300px',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

const MediaItem = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  cursor: 'pointer',
}));

const Gallery = () => {
  const [value, setValue] = useState(0);
  const [photoBoothMedia, setPhotoBoothMedia] = useState([]);
  const [nightclubMedia, setNightclubMedia] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadGalleries = async () => {
      setLoading(true);
      const [photoBoothFiles, nightclubFiles] = await Promise.all([
        loadGalleryImages('360photobooth'),
        loadGalleryImages('nightclub')
      ]);
      setPhotoBoothMedia(photoBoothFiles);
      setNightclubMedia(nightclubFiles);
      setLoading(false);
    };

    loadGalleries();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderGalleryGrid = (mediaFiles) => (
    <Grid container spacing={3} sx={{ mt: 4 }}>
      {mediaFiles.map((mediaUrl, index) => (
        <Grid item xs={12} sm={6} md={4} key={mediaUrl}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <MediaItem whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
              {isVideo(mediaUrl) ? (
                <video
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                  controls
                  muted
                  playsInline
                >
                  <source 
                    src={mediaUrl} 
                    type={mediaUrl.toLowerCase().endsWith('.mov') ? 'video/quicktime' : `video/${mediaUrl.split('.').pop()}`}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <GalleryImage
                  src={mediaUrl}
                  alt={`Gallery image ${index + 1}`}
                />
              )}
            </MediaItem>
          </motion.div>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          Gallery
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
          Check out our latest events and experiences
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="360° Photo Booth" />
            <Tab label="Inflatable Nightclub" />
          </Tabs>
        </Box>

        {loading ? (
          <Typography variant="h6" align="center" sx={{ mt: 4 }}>
            Loading gallery...
          </Typography>
        ) : (
          <>
            {value === 0 && renderGalleryGrid(photoBoothMedia)}
            {value === 1 && renderGalleryGrid(nightclubMedia)}
          </>
        )}
      </Box>
    </Container>
  );
};

export default Gallery;
